<a mat-list-item routerLink="./home" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faHouse" class="me-4"></fa-icon>
    <span class="align-middle">Dashboard</span>
  </span>
</a>
@if (auth.canManageContacts()) {
  <a mat-list-item routerLink="./contacts" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faUsers" class="me-4"></fa-icon>
    <span class="align-middle">Assistiti</span>
  </span>
  </a>
}
@if (auth.canManageCompanies()) {
  <a mat-list-item routerLink="./companies" routerLinkActive="active">
    <span class="entry">
      <fa-icon [icon]="faHandshake" class="me-4"></fa-icon>
      <span class="align-middle">Aziende</span>
    </span>
  </a>
}
<a mat-list-item routerLink="./schedule" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faHourglassHalf" class="me-4"></fa-icon>
    <span class="align-middle">Scadenze</span>
  </span>
</a>
@if (auth.hasRole('REPORT') || auth.hasRole('MANTAINER')) {
  <mat-list-item role="treeitem">CONTROLLO DI GESTIONE</mat-list-item>
  <a mat-list-item routerLink="./projects" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faRocket" class="me-4"></fa-icon>
    <span class="align-middle">Progetti</span>
  </span>
  </a>
  <a mat-list-item routerLink="./costs" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faReceipt" class="me-4"></fa-icon>
    <span class="align-middle">Gestione Spese</span>
  </span>
  </a>
  <a mat-list-item routerLink="./vacations" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faPlane" class="me-4"></fa-icon>
    <span class="align-middle">Ferie e Permessi</span>
  </span>
  </a>
  <a mat-list-item routerLink="./user-projects" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faUserClock" class="me-4"></fa-icon>
    <span class="align-middle">Esecuzione interventi</span>
  </span>
  </a>
  <a mat-list-item routerLink="./user-plannings" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faThumbtack" class="me-4"></fa-icon>
    <span class="align-middle">Programmazione</span>
  </span>
  </a>
  <a mat-list-item routerLink="./machines" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faBriefcase" class="me-4"></fa-icon>
    <span class="align-middle">Strumenti</span>
  </span>
  </a>
  <a mat-list-item routerLink="./salaries" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faFileInvoiceDollar" class="me-4"></fa-icon>
    <span class="align-middle">Buste paga</span>
  </span>
  </a>
}
@if (auth.canManageConfiguration()) {
  <mat-list-item role="treeitem">IMPOSTAZIONI</mat-list-item>

  <a mat-list-item routerLink="./users" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faCircle" class="me-4"></fa-icon>
    <span class="align-middle">Collaboratori</span>
  </span>
  </a>


  <a mat-list-item routerLink="./user-settings" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faCircle" class="me-4"></fa-icon>
    <span class="align-middle">HR</span>
  </span>
  </a>
  <a mat-list-item routerLink="./contact-settings" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faCircle" class="me-4"></fa-icon>
    <span class="align-middle">Persone e Aziende</span>
  </span>
  </a>
  <a mat-list-item routerLink="./schedule-settings" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faCircle" class="me-4"></fa-icon>
    <span class="align-middle">Scadenzario</span>
  </span>
  </a>

  <a mat-list-item routerLink="./email-settings" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faCircle" class="me-4"></fa-icon>
    <span class="align-middle">Email</span>
  </span>
  </a>

  <a mat-list-item routerLink="./assignment-settings" routerLinkActive="active">
  <span class="entry">
    <fa-icon [icon]="faCircle" class="me-4"></fa-icon>
    <span class="align-middle">Incarichi</span>
  </span>
  </a>
}


