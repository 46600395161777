import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {
  faBriefcase,
  faCalendarAlt,
  faCircle,
  faCoffee,
  faEdit,
  faFileMedical,
  faHandshake,
  faHourglassHalf,
  faHouse,
  faIdCard,
  faListAlt,
  faPlane,
  faReceipt,
  faRightFromBracket,
  faRocket,
  faThumbtack,
  faUserClock,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user = inject(AuthService).getUser();
  protected readonly faCoffee = faCoffee;
  protected readonly faBriefcase = faBriefcase;
  protected readonly faRocket = faRocket;
  protected readonly faHouse = faHouse;
  protected readonly faRightFromBracket = faRightFromBracket;
  protected readonly faListAlt = faListAlt;
  protected readonly faCalendarAlt = faCalendarAlt;
  protected readonly faUsers = faUsers;
  protected readonly faIdCard = faIdCard;
  protected readonly faEdit = faEdit;
  protected readonly faFileMedical = faFileMedical;
  protected readonly faHandshake = faHandshake;
  protected readonly faHourglassHalf = faHourglassHalf;
  protected readonly faThumbtack = faThumbtack;
  protected readonly faReceipt = faReceipt;
  protected readonly faPlane = faPlane;
  protected readonly faUserClock = faUserClock;
  protected readonly faCircle = faCircle;
  protected readonly faFileInvoiceDollar = faFileInvoiceDollar;

  constructor(
    public auth: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  logout(e: Event): void {
    e.preventDefault();
    this.auth.logout();
    this.router.navigate(['/global/login']);
  }
}
